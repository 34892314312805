import {Helmet} from "react-helmet"
import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { AuthContext } from "context/authContext"
import toast from 'react-hot-toast';
import axios from "axios"
import AppConfig from "constants/config"
import { Link, useNavigate } from "react-router-dom";

const SetPassword = props => {
  const navigate = useNavigate()
  const { userData, reloadUserData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password"),
      confirmPass: Yup.string().required("Please enter confirm password"),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        if (values.password && values.confirmPass && values.password !== values.confirmPass){
          setLoading(false);
          return formik.setFieldError("confirmPass", "Password and confirm password should be same")
        }
        if(userData.user_id){
          values.id = userData.user_id
          const { data } = await axios.post( `${AppConfig.baseUrl}/users/set_password`, values, {
            headers: { token: localStorage.getItem("token")},
          });
          if (!data.error) {
            toast.success('Logged in Successfully');
            reloadUserData()
            navigate(`/${localStorage.getItem('company')}/dashboard`)
          } else {
            throw new Error(data.title)
          }
        }else{
          values.id = userData.id
          const { data } = await axios.post( `${AppConfig.baseUrl}/customer/set_password`, values, {
            headers: { token: localStorage.getItem("token")},
          });
          if (!data.error) {
            toast.success('Logged in Successfully');
            reloadUserData()
            navigate(`/${localStorage.getItem('company')}/dashboard`)
          } else {
            throw new Error(data.title)
          }
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong")
      }
      setLoading(false);
    },
  })

  useEffect(() => {
    formik.setValues({
      password: "",
      confirmPass: "",
    })
  }, [userData])

  return (
    <React.Fragment>
      <Helmet>
        <title>
        LeaseVista | Set Password
        </title>
      </Helmet>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5 login-mt">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary bg-theme-color">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white login-head">Welcome To LeaseVista</h5>
                        <p className="text-white">Set Password</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password || ""}
                          invalid={
                            formik.touched.password && formik.errors.password ? true : false
                          }
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPass"
                          className="form-control"
                          placeholder="Enter confirm password"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPass || ""}
                          invalid={
                            formik.touched.confirmPass && formik.errors.confirmPass ? true : false
                          }
                        />
                        {formik.touched.confirmPass && formik.errors.confirmPass ? (
                          <FormFeedback type="invalid">{formik.errors.confirmPass}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end mt-3 d-grid">
                          <button
                            className="btn btn-primary bg-theme-color w-md "
                            type="submit"
                            disabled={loading}
                          >
                            {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            CONFIRM
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SetPassword
