import React, { useEffect, useState, useContext } from "react";
import {Helmet} from "react-helmet";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { map } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row, Table, Spinner } from "reactstrap";
import AddUserToClient from "./addUserToClient";
import Breadcrumbs from "components/Common/Breadcrumbclientdetails";
import MiniCards from "../../components/client/mini-card";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { AuthContext } from "context/authContext";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const ClientDetails = () => {
	const { userData } = useContext(AuthContext);
  const location = useLocation();
  const [addMoreOpen, setAddMoreOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [id, setId] = useState('');
  const [userTableData, setUserTableData] = useState([]);
  const [columns,setColumns] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState([])
  const [miniCards, setMiniCards] = useState([
    { title: "Location", iconClass: "bx-map", text: "CA" },
    { title: "Total leases", iconClass: "bx-file", text: "31" },
    { title: "Activity log", iconClass: "bx-time", text: <NavLink className="txt-theme-color text-decoration-underline" to={`/${localStorage.getItem('company')}/clients/activitylog/${id}`}>View</NavLink> }
  ]);
  const [leaseCountData, setLeaseCountData] = useState({})
  const [totatLeases, setTotalLeases] = useState(0)

  const unChangedColumns = [
    {
      name: "user",
      label: "User",
      options : {
        sort:true,
        setCellProps: () => ({style: {minWidth: '100px', height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite: (dataIndex) => (
          <>
            {userTableData && (!userTableData[dataIndex] || !userTableData[dataIndex].user_id || !userTableData[dataIndex].user_id.profile_picture) ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <div className="avatar-title rounded-circle">
                    {userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.name.charAt(0)}
                  </div>
                </div>
                <div className="client">
                  <div>{userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.name}</div>
                  <div>{userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.email}</div>
                </div>
              </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="avatar-sm">
                    <img
                      className="rounded-circle avatar-sm"
                      src={userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.profile_picture}
                      alt=""
                    />
                  </div>
                  <div className="client">
                    <div>{userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.name}</div>
                    <div>{userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.email}</div>
                  </div>
                </div>
              )}
          </>
        ),
      }
    },
    {
      name: "type",
      label: "Type",
      options : {
        customBodyRenderLite: (dataIndex) => (<div className="table-data-margin">{`${userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.type === 'user' ? `${userData.company && userData.company.toUpperCase()} `: ''}${userTableData && userTableData[dataIndex] && userTableData[dataIndex].user_id && userTableData[dataIndex].user_id.type ? userTableData[dataIndex].user_id.type.replace("_", " ").toUpperCase() : 'NA'}` || 'NA'}</div>)
      }
    },
    {
      name: "assignement_date",
      label: "Assigned Since",
      options: {
        customBodyRender: (value) => (
          <div className="table-data-margin">
            {value ? moment.utc(value).format('MM/DD/YYYY HH:mm') : 'NA'}
          </div>
        )
      }
    },
    {
      name: "access_type",
      label: "Access Type",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <div className="table-data-margin">
            <select
              id="formrow-InputState"
              className={`drp text-${userTableData && userTableData[dataIndex] && userTableData[dataIndex].access_type === 'full_access' ? 'success' : 'warning'}`}
              style={{ border: 'none', width: 'auto',fontWeight:'bold' }}
              onChange={(e) => handleAccessTypeChange(e, (userTableData && userTableData[dataIndex]))}
            >
              <option name="active" value="read_only" selected={userTableData && userTableData[dataIndex] && userTableData[dataIndex].access_type === 'read_only'}>READ ONLY</option>
              <option name="disable" value="full_access" selected={userTableData && userTableData[dataIndex] && userTableData[dataIndex].access_type === 'full_access'}>FULL ACCESS</option>
              <option name="disable" value="remove_access">REMOVE ACCESS</option>
            </select>
          </div>
        )
      }      
    }
  ]

  const getModules = async() =>{
    try{
        const {data} = await axios.post(`${AppConfig.baseUrl}/module/getCustomerModules`,{},{
            headers: { token: localStorage.getItem("token") }
        })
        if(!data.error){
            setModuleList(data.data)
        }else{
            throw new Error(data.title);
        }
    }catch(error){
        toast.error(error.message || "something went wrong");

    }
  }

  useEffect(() => {
    const moduleData = moduleList.filter(m => m.tag == 'audit_category')
    if (moduleData && moduleData[0] && clientDetails && clientDetails.modules && clientDetails.modules.includes('audit_category')) {
      setColumns([...unChangedColumns,
        {
          name: "can_review",
          label: "Lease review",
          options:{
            customBodyRenderLite: (dataIndex) => (
              <div className="d-flex mb-0">
                  <div className="form-check form-switch form-switch-md mt-2 ms-3">
                      <input
                          type="checkbox"
                          className="form-check-input switch-icon-color"
                          id="can_review"
                          name="can_review"
                          checked = {(userTableData && userTableData[dataIndex] && userTableData[dataIndex].can_review) || false}
                          onChange={(e) => {
                            changeCanReview((userTableData && userTableData[dataIndex] && userTableData[dataIndex]._id),e.target.checked,(userTableData && userTableData[dataIndex] && userTableData[dataIndex].client_id._id))
                          }}
                      />
                  </div>
              </div>
            )
          }
        }
      ])
    }else{
      setColumns(unChangedColumns)
    }
  }, [moduleList,clientDetails,userData,userTableData])

  useEffect(() => {
    setId((location.search).replace('?', ''));
  }, [])

  useEffect(() => {
    if(id){
      getClientDetails(id);
    }
    getModules();
  },[id])

const changeCanReview = async (userclientid,review,clientid)=>{
  setLoading(true)
  try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/updateReview`,{id:userclientid,review:review},{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success("User review updated successfully");
        getClientDetails(clientid)
      }
      else{
        throw new Error(data.title);
      }
  } catch (error) {
      toast.error(error.message || 'Something went wrong');
  }
  setLoading(false)
}

  const getClientDetails = async (client_id) => {
    setLoading(true)
      try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/customer/clientdetails?id=${client_id}`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            const total = data.leaseData ? Number(data.leaseData.activeLeases || 0) + ((Number(data.leaseData.terminatedLeases || 0) + Number(data.leaseData.expiredLeases || 0) + Number(data.leaseData.draftedLeases || 0))/4) : 0
            setTotalLeases(total || 0)
            setLeaseCountData(data.leaseData)
            setClientDetails(data.clientDetails)
          }
          else{
            toast.error(data.title);
            navigate(`/${localStorage.getItem('company')}/clients`)
          }
      } catch (error) {
          toast.error(error.message || 'Something went wrong');
      }
      setLoading(false)
  }

  const getUsers = async () => {
    setLoadingUsers(true)
    setUserTableData([])
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_clients_user?client_id=${clientDetails._id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            setUserTableData(data.userData || [])
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoadingUsers(false)
  }
  
  useEffect(() => {
    if(clientDetails && clientDetails._id){
      getUsers();
    }
  }, [clientDetails])

  useEffect(() => {
      setMiniCards([
        { title: "Location", iconClass: "bx-map", text: clientDetails.address },
        { title: "Total leases", iconClass: "bx-file", text: 
          <div 
            className="table-data-margin"
            tabIndex="0" dataToggle="tooltip" 
            title={`Active Leases: ${leaseCountData.activeLeases || 0} \nTerminated Leases: ${leaseCountData.terminatedLeases || 0} \nInactive Leases: ${leaseCountData.expiredLeases || 0} \nDrafted Leases: ${leaseCountData.draftedLeases || 0}`}
          >
            {totatLeases}
          </div>
        },
        { title: "Activity log", iconClass: "bx-time", text: <NavLink className="txt-theme-color text-decoration-underline" to={`/${localStorage.getItem('company')}/clients/activitylog/${id}`}>View</NavLink> }
      ])
  },[clientDetails])

  const toggleAddMore = () => {
    setAddMoreOpen(!addMoreOpen);
  }

  const handleAccessTypeChange = async(e, user) =>{
    setLoading(true)
    try {
      let mainData ={}
      if(e.target.value === 'remove_access'){
        const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/remove_access`, {user_id: user.user_id._id, client_id: user.client_id._id},{
          headers: { token: localStorage.getItem("token") }
        });
        mainData = data;
      }else{
        const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/change_access_type`, {id: user._id, access_type: e.target.value},{
          headers: { token: localStorage.getItem("token") }
        });
        mainData = data;
      }

      if(!mainData.error){
        toast.success(e.target.value === 'remove_access' ? "User access removed successfully" : "User access type updated successfully");
        getClientDetails(id || user.client_id._id)
      }
      else{
        throw new Error(mainData.title);
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message || 'Something went wrong');
    }
  }

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Client Details</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs title="clientdetail" breadcrumbItem="" name={clientDetails.client} image={clientDetails.logo} type="clientdetails" />
          {
            loading ?
              <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
            :
            <Row>
              <Col xl="4" lg="4" md="4" xxl="4">
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle className="mb-4">OVERVIEW</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Name :</th>
                            <td>{clientDetails.client}</td>
                          </tr>
                          <tr>
                            <th scope="row">Contact name:</th>
                            <td>{clientDetails.contact_person}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email :</th>
                            <td>{clientDetails.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address:</th>
                            <td>{clientDetails.address}</td>
                          </tr>
                          <tr>
                            <th scope="row">Client since:</th>
                            <td>{clientDetails.added_on && moment.utc(clientDetails.added_on).format('MM/DD/YYYY HH:mm')}</td>
                          </tr>
                          <tr>
                            <th scope="row">Added by:</th>
                            <td>{clientDetails.fromcustomer ? clientDetails.fromcustomer.admin_name : clientDetails.fromuser ? clientDetails.fromuser.name : ''}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8" lg="8" md="8" xxl="8">
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>
                <Card>
                  <CardBody>
                    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                      MUIDataTableToolbar:{
                          styleOverrides:{
                          actions: {
                              display: 'contents'
                          }
                          }
                      }
                      }})}>
                      <MUIDataTable
                        title={
                          <Row>
                            <Col className="mt-2">USERS</Col>
                            <Col>
                              <div className=" d-flex dataTables_filter">
                                  <button
                                    type="button"
                                    className="btn btn-lbusers dataTables_filter"
                                    onClick={toggleAddMore}
                                  >ADD MORE
                                  </button>&nbsp;
                                </div>
                            </Col>
                          </Row>
                          }
                        data={userTableData}
                        columns={columns}
                        key={Math.random()}
                        options={{ 
                          rowsPerPage:20, 
                          print:false, 
                          download:false, 
                          filter:false, 
                          selectableRows:"none", 
                          search:false, 
                          rowsPerPageOptions:[], 
                          responsive:'scroll',
                          textLabels: {
                            body: {
                              noMatch: loadingUsers ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                            },
                            viewColumns: {
                              title: "",
                            },
                          },
                          downloadOptions:{
                            filename:'users.csv'
                          }
                        }}
                        
                      />
                    </ThemeProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      <AddUserToClient addOpen={addMoreOpen} toggleAdd={toggleAddMore} setOpenPopup={setAddMoreOpen} client={clientDetails} getClientDetails={getClientDetails} />
    </Layout>
  )
}


export default ClientDetails