import React,{useEffect, useState, useContext } from "react"
import { Row, Col, Card, } from "reactstrap"
import {Helmet} from "react-helmet";
import ActiveClient from "./activeClient";
import ArchiveClient from "./archiveClient";
import BreadcrumbManual from "components/Common/BreadcrumbManual";
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext";
import Layout from "components/VerticalLayout";

const Clients = () => {
  const [ clientData, setClientsData ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ leaseByClient, setLeaseByClient ] = useState([]);
  const [ custData, setCustData ] = useState({})
	const { userData } = useContext(AuthContext);
  const [ clientType, setClientType ] = useState('active')
  const [ originalData, setOriginalData ] = useState([]);

  const getCustomerData = async() => {
    setLoading(true)
    try {
        const id = userData.info_id;
        const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            setCustData(data.custData)
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
        setLoading(false)
    }
  }
  useEffect(() => {
    if(userData.info_id){
        getCustomerData()
    }
  },[userData])

  const getClientsData = async() =>{
    setLoading(true)
    setClientsData([])
    setLeaseByClient([])
    try {
      let type = ''
      if(!custData || !custData.customer_id || !custData.customer_id.modules || !custData.customer_id.modules.includes('lessor_gasb')){
        type = "lessee"
      }
      const {data} = await axios.get(`${AppConfig.baseUrl}/client/list_client?type=${type}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setLeaseByClient(data.leaseDataByClient || [])
        setClientsData(data.clientData || []);
        setOriginalData(data.clientData || []);
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  useEffect(() => {
    if(custData && custData.customer_id){
      getClientsData();
    }
  }, [custData])

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Clients</title>
        </Helmet>
        <div className="container-fluid">
          <BreadcrumbManual title="Tables" breadcrumbItem={clientType == 'archieve' ? "Archieved Clients" : "Active Clients"} type="client" getClientsData={getClientsData} clientType={clientType} setClientType={setClientType} />

          <Row>
            <Col className="col-12">
              <Card>
                { clientType == 'archieve' ? 
                  <ArchiveClient/>
                :
                  <ActiveClient loading={loading} getClientsData={getClientsData} clientData={clientData} setClientsData={setClientsData} originalData={originalData} getCustomerData={getCustomerData} leaseByClient={leaseByClient} setLeaseByClient={setLeaseByClient}/>
                }
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default Clients
