import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import React, {useState} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async(values) => {
      setLoading(true);
      try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/customer/forget_password`,values,{
          headers: { token: localStorage.getItem("token") },
        });
        if(!data.error){
          localStorage.setItem('temp_token', data.token)
          navigate({pathname: "/factorauth"});
          toast.success('Otp sent successfully');
        }
        else{
          throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoading(false);
    }
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>
          LeaseVista | Forget Password
        </title>
      </Helmet>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5 login-mt">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary bg-theme-color">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white login-head">Welcome To LeaseVista</h5>
                        <p className="text-white">Forgot Password ?</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end mt-3 d-grid">
                          <button
                            className="btn btn-primary w-md bg-theme-color "
                            type="submit"
                            disabled={loading}
                          >
                            {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SEND OTP
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-5 text-center">
                        <p>
                          Go back to{" "}
                          <Link to="/login" className="font-weight-medium text-primary txt-theme-color">
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default ForgetPasswordPage
