import {Helmet} from "react-helmet"
import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { AuthContext } from "context/authContext"
import toast from 'react-hot-toast';
import axios from "axios"
import AppConfig from "constants/config"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Layout from "components/VerticalLayout"

const UserProfile = () => {
  const navigate = useNavigate()
  const { userData, reloadUserData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your name"),
      email: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (values.password && !values.confirmPass){
          return formik.setFieldError("confirmPass", "Please enter confirm password")
        }
        if (values.password && values.confirmPass && values.password !== values.confirmPass){
          return formik.setFieldError("confirmPass", "Password and confirm password should be same")
        }
        if(userData.user_id){
          values.id = userData.user_id
          const { data } = await axios.post( `${AppConfig.baseUrl}/users/edit_profile`, values, {
            headers: { token: localStorage.getItem("token")},
          });
          if (!data.error) {
            toast.success("Account updated successfully")
            reloadUserData()
          } else {
            throw new Error(data.title)
          }
        }else{
          values.id = userData.id
          values.is_2fa_active = userData.is_2fa_active
          values._2fa_frequency = userData._2fa_frequency
          const { data } = await axios.post( `${AppConfig.baseUrl}/customer/edit_profile`, values, {
            headers: { token: localStorage.getItem("token")},
          });
          if (!data.error) {
            toast.success("Account updated successfully")
            reloadUserData()
          } else {
            throw new Error(data.title)
          }
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong")
      }
      setLoading(false);
    },
  })

  const getCustDetails = async() =>{
    try{
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${userData.info_id}`,{
        headers: {token: localStorage.getItem('token')},
      });
      if(!data.error){
        setUserDetails(data.custData)
      } else {
        throw new Error(data.title)
      }
    }catch(err){
      console.log(err)
    }
  }

  const getUserDetails = async() =>{
    try{
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/userdetails?id=${userData.user_id}`,{
        headers: {token: localStorage.getItem('token')},
      });
      if(!data.error){
        setUserDetails(data.userDetails)
      } else {
        throw new Error(data.title)
      }
    }catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    formik.setValues({
      name: userData.user_id ? userData.user_name : userData.name,
      email: userData.user_id ? userData.user_email : userData.email,
      password: "",
      confirmPass: "",
    })
    if(userData.user_id){
      getUserDetails()
    }
    else if(userData.id){
      getCustDetails()
    }
  }, [userData])

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Profile</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="LeaseVista" breadcrumbItem="EDIT PROFILE" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h5 className="card-title mb-4"><i className="mdi mdi-arrow-right txt-theme-color arrow-spacing"></i>Basic details</h5>
                  <Row>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <Label className="form-label mt-3">Name</Label>
                        <Input
                          name="name"
                          placeholder="Enter name"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-danger">{formik.errors.name}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <Label className="form-label mt-3">Email</Label>
                        <Input
                          name="email"
                          placeholder="Enter Email"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email || ""}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">{formik.errors.email}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <h5 className="card-title mt-5 mb-4"><i className="mdi mdi-arrow-right txt-theme-color arrow-spacing"></i>Change password</h5>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <FormGroup className="form-group">
                            <Label className="form-label">New password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter New password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            {formik.touched.password && formik.errors.password && (
                              <div className="text-danger">{formik.errors.password}</div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="form-group">
                            <Label className="form-label">Confirm new password</Label>
                            <Input
                              name="confirmPass"
                              className="form-control"
                              placeholder="Confirm New password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.confirmPass}
                            />
                            {formik.touched.confirmPass && formik.errors.confirmPass && (
                              <div className="text-danger">{formik.errors.confirmPass}</div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        You have last approved <a href={`/terms_and_conditions/view`} target="_blank" rel="noreferrer"> terms and conditions </a>on {userData.user_id ? moment(userDetails.terms_checked_date).format('MM/DD/YYYY HH:mm') : userDetails.customer_id ? moment(userDetails.customer_id.terms_checked_date).format('MM/DD/YYYY HH:mm'): ''}
                        </Col>
                      </Row>
                      <Row>
                        <div className="text-end editprof-save-btn mt-4">
                          <Button type="submit" className="bg-theme-color" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SAVE
                          </Button>
                        </div>
                      </Row>
                    </Form>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default UserProfile
