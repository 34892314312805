import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Input, Spinner} from "reactstrap"
import {Helmet} from "react-helmet";
import EditUser from "./editUser";
import DeleteUser from "./deleteUser";
import ButtonDropdownClient from "components/client/ButtonDropdown";
import "../Myclients/datatables.scss"
import "../../assets/scss/manual/manual.scss"
import BreadcrumbManual from "components/Common/BreadcrumbManual";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext";
import {commaSeperatedNumberWithoutDecimal} from "helpers/stringHelper"
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const Users = () => {
	const { userData } = useContext(AuthContext);
  const [usersData, setUsersData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [usersCount, setUsersCount] = useState({});
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        setCellProps: () => ({style: {minWidth: '100px', height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite:(dataIndex)=>{
          return <>
            {usersData && usersData[dataIndex] && !usersData[dataIndex].profile_picture ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <div className="avatar-title rounded-circle">
                    {usersData && usersData[dataIndex] && usersData[dataIndex].name.charAt(0)}
                  </div>
                </div>
                <div className="client">
                  <div>{usersData && usersData[dataIndex] && usersData[dataIndex].name}</div>
                </div>
              </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="avatar-sm">
                    <img
                      className="rounded-circle avatar-sm"
                      src={usersData && usersData[dataIndex] && usersData[dataIndex].profile_picture}
                      alt=""
                    />
                  </div>
                  <div className="client">
                    <div>{usersData && usersData[dataIndex] && usersData[dataIndex].name}</div>
                  </div>
                </div>
              )}
          </>
        },
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRenderLite:(dataIndex)=>(<div className="table-data-margin">{(usersData && usersData[dataIndex] && usersData[dataIndex].email) || 'NA'}</div>)
      }
    },
    {
      name: "type",
      label: "Role",
      options: {
        customBodyRenderLite:(dataIndex)=>(<div className="table-data-margin">{`${usersData && usersData[dataIndex] && usersData[dataIndex].type === 'user' ? `${userData && userData.company ? userData.company.toUpperCase() : ''} `: ''}${usersData && usersData[dataIndex] && usersData[dataIndex].type ? usersData[dataIndex].type.replace("_", " ").toUpperCase() : 'NA'}` || 'NA'}</div>),
      }
    },
    {
      name: "no_of_clients",
      label: "No of clients ",
      options: {
        customBodyRenderLite:(dataIndex)=>(<div className="table-data-margin">{usersData && usersData[dataIndex] && usersData[dataIndex].no_of_clients ? commaSeperatedNumberWithoutDecimal(usersData[dataIndex].no_of_clients) : 0}</div>)
      }
    },
    {
      name: "status",
      label: "Status",
      searchable: false,
      options: {
        customBodyRenderLite:(dataIndex)=>(
          <div className="table-data-margin">
            {
              (usersData && usersData[dataIndex] && usersData[dataIndex].email == userData.email) || (usersData && usersData[dataIndex] && usersData[dataIndex].email == userData.user_email) ?
              <div className={`text-${usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'success' : 'warning'} ms-1`}
                style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
              >
                {usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'ACTIVE' : 'DISABLED'}
              </div>
              :
              <select
                id="formrow-InputState"
                className={`drp text-${usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'success' : 'warning'}`}
                style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
                onChange={(e) => handleStatusChange(e, (usersData && usersData[dataIndex] && usersData[dataIndex]))}
              >
                <option name="active" value={true} selected={usersData && usersData[dataIndex] && usersData[dataIndex].is_active}>ACTIVE</option>
                <option name="disable" value={false} selected={usersData && usersData[dataIndex] && !usersData[dataIndex].is_active}>DISABLED</option>
              </select>
            }
          </div>
        ),
      }
    },
    {
      name: "menu",
      label: "Action",
      options: {
        customBodyRenderLite:(dataIndex)=>(
          <div className="mt-2">
            {
              (usersData && usersData[dataIndex] && usersData[dataIndex].email == userData.email) || (usersData && usersData[dataIndex] && usersData[dataIndex].email == userData.user_email) ? 
                <Link to={{pathname:`/${localStorage.getItem('company')}/users/details`, search: usersData && usersData[dataIndex] && usersData[dataIndex]._id}}><Button  className="btn btn-sm bg-theme-color">View</Button></Link>  
              :
              <ButtonDropdownClient type='users_list' setEditOpen={setEditOpen} setDeleteOpen={setDeleteOpen} user={usersData && usersData[dataIndex]} setSelectedUser={setSelectedUser}/>
            }
          </div>
        )
      }
    },
  ]

  const handleStatusChange = async(e, user) =>{
    setLoading(true)
    setUsersData([])
    try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/users/change_status`, {id: user._id, is_active: e.target.value},{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success("User status changed successfully");
        getUserData()
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }

  const getUserData = async() =>{
    setLoading(true);
    setUsersData([])
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/users/list_user`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setUsersData(data.usersData || []);
        setOriginalData(data.usersData || []);
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const getUsersCount = async() => {
    setLoading(true);
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/get_users_count?id=${userData.id}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setUsersCount(data.usersCount || []);
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false);
  }

  useEffect(() => {
    if(userData.id){
      getUserData();
      getUsersCount();
    }
  }, [userData])

  // toggle edit account modal
  const toggleEdit = () => {
    setEditOpen(!editOpen);
  }
  // toggle delete account modal
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.email.match(new RegExp(value,"i")) || v.name.match(new RegExp(value,"i")))
    setUsersData(data)
  }

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Users</title>
        </Helmet>
        <div className="container-fluid">
          <BreadcrumbManual title="Tables" breadcrumbItem="Users" type="user" getUserData={getUserData} usersCount={usersCount}/>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                    <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                      <MUIDataTable
                        title={
                          <Row className="row">
                            <Col md="6" className="search-p">
                              <div className="me-2 mb-3 mt-3">
                                <div className="position-relative sw">
                                <Input
                                    placeholder="Search"
                                    type="text"
                                    value={search}
                                    onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                                />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        }
                        data={usersData}
                        columns={columns}
                        options={{ 
                          rowsPerPage:20, 
                          print:false, 
                          download:true, 
                          filter:false, 
                          selectableRows:"none", 
                          search:false, 
                          rowsPerPageOptions:[], 
                          responsive:'scroll',
                          download: true,
                          textLabels: {
                            body: {
                              noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                            },
                            viewColumns: {
                              title: "",
                            },
                          } ,
                          downloadOptions:{
                            filename:'users.csv'
                          },
                          setRowProps: (row,dataIndex, rowIndex) =>{
                            return {
                              style: {
                                backgroundColor:
                                (usersData[dataIndex].email == userData.email || usersData[dataIndex].email == userData.user_email) ? 'rgb(238, 237, 237)' : "",
                              },
                            };
                          },
                          onDownload:( buildHead, buildBody, columns, data) =>{
                            try{
                              const formattedData = []
                              for(const [index,row] of data.entries()){
                                const newRow = [ ...row.data ];
                                newRow[2] = `${usersData && usersData[index] && usersData[index].type === 'user' ? `${userData && userData.company ? userData.company.toUpperCase() : ''} `: ''}${usersData && usersData[index] && usersData[index].type ? usersData[index].type.replace("_", " ").toUpperCase() : 'NA'}` || 'NA'
                                newRow[3] = newRow[3] ? newRow[3] : 'NA'
                                newRow[4] = usersData && usersData[index] && usersData[index].is_active ? 'ACTIVE' : 'DISABLED'
                                formattedData.push({ ...row, data: newRow })
                              }
                              const csv = buildHead(columns) + buildBody(formattedData);
                              return csv
                            }catch(err){
                              console.log(err)
                              return buildHead(columns) + buildBody(data);
                            }                              
                          }
                        }}
                      />
                    </ThemeProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <EditUser editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} user={selectedUser} getUserData={getUserData} setSelectedUser={setSelectedUser} />
      <DeleteUser deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} user={selectedUser} getUserData={getUserData} setSelectedUser={setSelectedUser} />
    </Layout>
  )
}

export default Users
