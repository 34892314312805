import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Modal, Label, Input, InputGroup } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import avatar from "../../assets/images/users/avatar-1.jpg";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext";
import moment from "moment";
import CropImage from "components/Common/CropImage";

const AddClient = (prop) => {
	const { userData } = useContext(AuthContext);
    const { addOpen, toggleAdd, setOpenPopup, getClientsData } = prop
    const [image, setImage] = useState(avatar);
    const [cropOpen, setCropOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [custData, setCustData] = useState({})

    const getCustomerData = async() => {
        setLoading(true)
        try {
            const id = userData.info_id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setCustData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    useEffect(() => {
        if(userData.info_id){
            getCustomerData()
        }
    },[userData])

    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            if(formik.values.has_transitioned && !formik.values.transition_date){
                setLoading(false)
                return formik.setFieldError("transition_date", "Transition Date Is Required");
            }
            values.logo = image !== avatar ? image : '';
            values.customer_id = userData.id;
            values.transition_date = values.transition_date ? new Date(moment(values.transition_date).format('YYYY-MM-DD')) : '';
            const {data} = await axios.post(`${AppConfig.baseUrl}/client/add_client`, values,{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                toast.success('Client added successfully');
                getClientsData();
                handleCloseDialog();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            handleCloseDialog();
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const handleCloseDialog = () => {
        formik.resetForm();
        setImage(avatar);
        setOpenPopup(false);
    }
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            client: '',
            contact_person: '',
            address: '',
            phone_number: '',
            reporting_standard:'FASB ASC 842',
            interest_rate_method: 'monthly_compound',
            has_transitioned: false,
            transition_date: '',
            calculation_type: 'monthly',
            client_type: 'lessee',
        },
        validationSchema: Yup.object({
            client: Yup.string().required("Please Enter Company Name"),
            contact_person: Yup.string().required("Please Enter Contact Person Name"),
        }),
        onSubmit: handleSubmit
    });

    const onSelectFile = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setCropOpen(true);
        };
        reader.readAsDataURL(files[0]);
    };

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
            }}
            size="lg"
            centered
        >
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Add Client</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenPopup(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="image-area" style={{marginTop: '20px'}}>
                                <label style={{width: '100px', height:'100px', cursor:'pointer'}}>
                                    <input type="file" multiple={false} onChange={onSelectFile} style={{display:'none'}} />
                                        <img src={image} />
                                </label>
                                {
                                    image !== avatar && 
                                    <i className='bx bx-x remove-image' style={{display: 'inline-block'}} onClick={() => setImage(avatar)}></i>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="client" className="col-form-label">
                                    Company Name:
                                </Label>
                                <Input
                                    type="text"
                                    id="client"
                                    name="client"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.client || ""}
                                />
                                {formik.touched.client && formik.errors.client &&
                                    <div className="text-danger">{formik.errors.client}</div>
                                }
                            </div>
                        </Col>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="contact_person" className="col-form-label">
                                    Contact Name:
                                </Label>
                                <Input
                                    type="text"
                                    id="contact_person"
                                    name="contact_person"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact_person || ""}
                                />
                                {formik.touched.contact_person && formik.errors.contact_person &&
                                    <div className="text-danger">{formik.errors.contact_person}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3">
                                <Label for="address" className="col-form-label">
                                    Company Address:
                                </Label>
                                <Input
                                    type="textarea"
                                    id="address"
                                    name="address"
                                    maxLength="225"
                                    rows="1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address || ""}
                                />
                                {formik.touched.address && formik.errors.address &&
                                    <div className="text-danger">{formik.errors.address}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="email" className="col-form-label">
                                    Email:
                                </Label>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email || ""}
                                />
                                {formik.touched.email && formik.errors.email &&
                                    <div className="text-danger">{formik.errors.email}</div>
                                }
                            </div>
                        </Col>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="phone_number" className="col-form-label">
                                    Phone No:
                                </Label>
                                <Input
                                    type="number"
                                    id="phone_number"
                                    name="phone_number"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone_number || ""}
                                />
                                {formik.touched.phone_number && formik.errors.phone_number &&
                                    <div className="text-danger">{formik.errors.phone_number}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            custData && custData.customer_id && custData.customer_id.modules && custData.customer_id.modules.includes('lessor_gasb') &&
                            <Col lg={6} md={6} xl={6} xxl={6}>
                                <div className="mt-3">
                                    <Label for="client_type" className="col-form-label">
                                        Client type:
                                    </Label>
                                    <select className="form-control arrows" 
                                    id="client_type"
                                    name="client_type"
                                    placeholder="Select client type"
                                    onChange={(e)=>{
                                        if(e.target.value == 'lessor'){
                                            formik.handleChange(e)
                                            formik.setFieldValue('reporting_standard','GASB 87')
                                        }else{
                                            formik.handleChange(e)
                                        }                                    
                                    }}
                                    value={formik.values.client_type} style={{display: "inline-block"}}>
                                        <option name="lessee" value="lessee">Lessee</option>
                                        <option name="lessor" value="lessor">Lessor (experiment)</option>
                                    </select>
                                </div>
                            </Col>
                        }
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="reporting_standard" className="col-form-label">
                                    Reporting Standard:
                                </Label>
                                <select className="form-control arrows" 
                                id="reporting_standard"
                                name="reporting_standard"
                                placeholder="Select reporting standard"
                                onChange={formik.handleChange}
                                disabled={formik.values.client_type == 'lessor'}
                                value={formik.values.reporting_standard} style={{display: "inline-block"}}>
                                    <option value="FASB ASC 842">FASB ASC 842</option>
                                    <option value="GASB 87">GASB 87</option>
                                    <option value="IFRS 16">IFRS 16</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            custData && custData.customer_id && custData.customer_id.modules && custData.customer_id.modules.includes('daily_leases') &&
                            <Col lg={6} md={6} xl={6} xxl={6}>
                                <div className="mt-3">
                                    <Label for="calculation_type" className="col-form-label">
                                        Lease Calculation Type
                                    </Label>
                                    <select className="form-control arrows" name='calculation_type' id="calculation_type"
                                        onChange={formik.handleChange}
                                        value={formik.values.calculation_type} onBlur={() => formik.setFieldTouched("calculation_type")}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="daily">Daily</option>
                                    </select>
                                </div>
                                {formik.touched.calculation_type && formik.errors.calculation_type && (
                                    <span className='text-danger'>{formik.touched.calculation_type && formik.errors.calculation_type}</span>
                                )}
                            </Col>
                        }
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="mt-3">
                                <Label for="interest_rate_method" className="col-form-label">
                                    Interest Rate Method
                                </Label>
                                <select className="form-control arrows" name='interest_rate_method' id="interest_rate_method"
                                    onChange={formik.handleChange}
                                    value={formik.values.interest_rate_method} onBlur={() => formik.setFieldTouched("interest_rate_method")}
                                >
                                    <option value="true_annual">True annual rate</option>
                                    <option value="monthly_compound">Monthly compounded rate</option>
                                </select>
                            </div>
                            {formik.touched.interest_rate_method && formik.errors.interest_rate_method && (
                                <span className='text-danger'>{formik.touched.interest_rate_method && formik.errors.interest_rate_method}</span>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} md={10} xl={10} xxl={10}>
                            <div className="d-flex">
                                <div className="mt-3">
                                    <Label for="confirm" className="col-form-label">
                                    Will you be using LeaseVista to transition to {formik.values.reporting_standard} ?
                                    </Label>
                                </div>
                                <div className="form-check form-switch mt-4 ms-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input switch-icon-color"
                                        id="has_transitioned"
                                        name="has_transitioned"
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            formik.setFieldValue('transition_date', '')
                                        }}
                                    />
                                    <label
                                        className="form-check-label "
                                        htmlFor="has_transitioned"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <h6 className="mt-3">Transition Date:</h6>
                            <InputGroup>
                                <Flatpickr
                                    onClose={([date]) => {
                                        formik.setFieldValue("transition_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                    }}
                                    name="transition_date"
                                    onBlur={() => formik.setFieldTouched("transition_date")}
                                    value={formik.values.transition_date}
                                    className="form-control d-block"
                                    disabled={!formik.values.has_transitioned}
                                    style={{cursor: !formik.values.has_transitioned ? 'not-allowed' : 'pointer'}}
                                    placeholder="Transition Date"
                                    options={{
                                        dateFormat: "m/d/Y",
                                        allowInput: true,
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                            {formik.touched.transition_date && formik.errors.transition_date && (
                                <div className="text-danger">{formik.errors.transition_date}</div>
                            )}
                        </Col>
                    </Row>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={image} setImage={setImage} />
        </React.Fragment>
        </Modal>
    )
}

export default AddClient